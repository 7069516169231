@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800");

body {
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5 {
  &.cmat-h1 {
    font-size: 2em;
  }
  &.cmat-h2 {
    font-size: 1.5em;
  }
  &.cmat-h3 {
    font-size: 1.17em;
  }
  &.cmat-h4 {
    font-size: 1em;
  }
  &.cmat-h5 {
    font-size: 0.83em;
  }
}

#data-system-chat-bot-widget {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}
